import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import dataV from '@jiaminghi/data-view';

/** init domain config */
import baseurl from "@/config";
Vue.prototype.$baseUrl = baseurl;
// 引入全局css
import './assets/scss/style.scss';
// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';

//引入echart
//4.x 引用方式
import echarts from 'echarts'
//5.x 引用方式为按需引用
//希望使用5.x版本的话,需要在package.json中更新版本号,并切换引用方式
//import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false;
// 引入ant
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css';

// axios

// 图片
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
// 语音播报
import './utils/speech'
// 地图

import VueAMap from '@vuemap/vue-amap';
import '@vuemap/vue-amap/dist/style.css'
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: 'aca6841135476541e2c65b5f5a56908f',
  securityJsCode: 'a7035a8cc8292e3b5468853bc8b30797',
  plugin: []
});


// 全局注册
Vue.component('icon', Icon);
Vue.use(dataV);
Vue.use(Antd);
Vue.use(preview)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
