// 语音播报
import Speech from 'speak-tts'
import Vue from "vue";
let speech = new Speech();
if (speech.hasBrowserSupport()){
    console.log("支持语音")
    console.log(Vue.prototype.$notification)
    // Vue.prototype.$notification.success({
    //     message: "支持语音",
    // })
}else {
    console.log("不支持语音")

    // Vue.prototype.$notification.error({
    //     message: "不支持语音",
    // })
}

speech.setLanguage("zh-CN");
speech.init().then(() => {});
Vue.prototype.$speech = speech;