import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

  {
    path: "/",
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },

  {
    path: '/index',
    name: 'index',
    component: () => import('../views/index.vue')
  },
  // {
  //   path: '/map',
  //   name: 'map',
  //   component: () => import('../views/MapSystem.vue')
  // },
]
const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  console.log("to", to)
  console.log("from", from)
  if (to.name === "index"){
    let token = localStorage.getItem("assToken");
    if (token){
      next()
    }else {
      next({
        name: "login"
      })
    }
  }
  next()
})
export default router