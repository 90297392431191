<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default{
  data(){
    return{
      beforeTime: 0,
      unloadTime: 0
    }
  },
  mounted() {
    window.addEventListener('beforeunload', this.handleBeforeUnload)
    window.addEventListener('unload', this.handleUnload)
  },
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
    window.removeEventListener('unload', this.handleUnload)
  },
  methods: {
    handleBeforeUnload(){
      console.log("执行handleBeforeUnload")
      this.beforeTime = new Date().getTime();
      // localStorage.removeItem("assToken");
      // localStorage.removeItem("userInfo");
    },
    handleUnload(){

      this.unloadTime = new Date().getTime();
      if (this.unloadTime - this.beforeTime <= 2){
        console.log("关闭页面")
        localStorage.removeItem("assToken");
        localStorage.removeItem("userInfo");
      }else{
        console.log("刷新页面")
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  width: 100vw;
  height: 100vh;
  background-color: #020308;
  overflow: hidden;
}
</style>
